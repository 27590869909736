import React from 'react';
import Landing from '../components/Landing/Landing';
import Localize from '../constants/Localize.json';
import { LangEnum } from '../types';

const Home = () => (
  <Landing
    lang={LangEnum.uk}
    title={Localize[LangEnum.uk].title}
    keywords={Localize[LangEnum.uk].keywords}
    description={Localize[LangEnum.uk].description}
  />
);

export default Home;
